import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Main } from './style';
import Overlay from '../../atoms/Overlay';
import Header from "../../organisms/Header";
import Footer from "../../organisms/Footer";
import { ScrollToTop } from '../../../utils/routes';

const RouterTemplate = ({ routes }) => {
    return (
        <Router>
            <Overlay />
            <Header navItems={routes} />
            <Main>
                <ScrollToTop />
                <Routes>
                    {routes.map((route, index) =>
                        <Route
                            key={index}
                            path={route.path}
                            element={route.element} />
                    )}
                </Routes>
            </Main>
            <Footer />
        </Router>
    );
}

export default RouterTemplate;