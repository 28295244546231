import styled from "styled-components";

const Container = styled.div(({ $drop, $visible, theme }) => `
    display: flex;
    column-gap: 32px;
    height: 100%;
    margin: 0 60px;

    ${$drop && `
        flex-direction: column;
        line-height: 60px;
        width: 50vw;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        margin: 0;
        padding-top: 88px;
        background-color: ${theme.blue1};
        transition: 800ms;
        transform: translateX(100%);

        ${$visible && `
            transform: translateX(0);
        `}

        z-index: 3;
    `}
`);

export { Container };