import ICONS from "./assets/icons";
import { PROPOSAL_CARDS_TEXT, DISCLAIMER_CARDS_TEXT } from "./strings/home";

const PROPOSAL_CARDS = [
    {
        icon: ICONS.ball,
        text: PROPOSAL_CARDS_TEXT[0]
    },
    {
        icon: ICONS.balance,
        text: PROPOSAL_CARDS_TEXT[1]
    },
    {
        icon: ICONS.tribune,
        text: PROPOSAL_CARDS_TEXT[2]
    },
];

const DISCLAIMER_CARDS = [
    {
        text: DISCLAIMER_CARDS_TEXT[0]
    },
    {
        text: DISCLAIMER_CARDS_TEXT[1]
    },
    {
        text: DISCLAIMER_CARDS_TEXT[2]
    },
];

export {PROPOSAL_CARDS, DISCLAIMER_CARDS}