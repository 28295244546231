import styled from "styled-components";

const Container = styled.section(({
    $height,
    $background,
    $bgColor
}) => `
    display: flex;
    ${$height && `height: ${$height}px`};
    padding: 0 8vw;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    ${$background && `background-image: url(${$background})`};
    ${$bgColor && `background-color: ${$bgColor}`};
    
    @media screen and (max-width: 1540px){
        ${$height && `height: ${($height - 80)}px`};
    }
`);

export { Container };