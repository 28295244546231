import styled from "styled-components";

const Container = styled.div(({ size = '100%' }) => `
    width: ${size};

    img {
        width: 100%;
    }
`);

export { Container }