import { useTheme } from "styled-components";

import { Container, Social, SocialLink } from "./style";
import Image from "../Image";
import Text from "../Text";
import ICONS from "../../../utils/constants/assets/icons";

const Partner = ({ logo, subtitle, social }) => {
    const theme = useTheme();

    const socialIcons = [
        ICONS.socialInsta.img,
        ICONS.socialWhats.img,
        ICONS.socialFaceb.img,
    ];

    return (
        <Container>
            <Image size={'70%'} src={logo} />
            <Text
                color={theme.white1}
                marginX={'auto'}
                marginY={'12px'}
                alignment={'center'}
            >
                {subtitle}
            </Text>
            <Social>
                {social.map((item, index) => {
                    return (
                        <SocialLink
                            key={index}
                            href={item.link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Image size={'24px'} src={socialIcons[item.id]} />
                        </SocialLink>
                    );
                })}
            </Social>
        </Container>
    );
}

export default Partner;