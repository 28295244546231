import { Container } from "./style";

const Image = ({ size, src }) => {
    return (
        <Container size={size}>
            <img src={src} alt='Imagem'/>
        </Container>
    );
}

export default Image;