import { Container } from "./style";

const Section = ({
    height,
    background,
    element,
    color
}) => {
    return (
        <Container
            $height={height}
            $background={background}
            $bgColor={color}
        >
            {element}
        </Container>
    );
}

export default Section;