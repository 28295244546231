import { useTheme } from "styled-components";

import { FlexibleList } from "./style";
import Text from "../../atoms/Text";
import Card from "../../atoms/Card";
import Frame from "../../atoms/Frame";
import Partner from "../../atoms/Partner";
import { PARTNERS_HOME } from "../../../utils/constants/partners";
import { PROPOSAL_CARDS, DISCLAIMER_CARDS } from "../../../utils/constants/cards";
import { PRESENTATION_TEXT, PROPOSAL_TEXT, DISCLAIMER_TEXT, PARTNERS_TEXT } from "../../../utils/constants/strings/home";


const HomePresentation = (props) => {
    const theme = useTheme();

    return (
        <Frame
            column
            mainAlign={'center'}>
            <Text
                title
                as={'h1'}
                maxW={'60'}
                color={theme.white1}>
                {PRESENTATION_TEXT.title}
            </Text>
            <Text
                title
                as={'h4'}
                maxW={'60'}
                marginY={'46px'}
                color={theme.gray3}>
                {PRESENTATION_TEXT.subtitle}
            </Text>
        </Frame>
    );
}

const HomeProposal = (props) => {
    const theme = useTheme();

    return (
        <Frame column altAlign={'center'}>
            <Text
                title
                as={'h2'}
                maxW={'50'}
                marginX={'auto'}
                marginY={'60px'}
                alignment={'center'}
                color={theme.blue1}>
                {PROPOSAL_TEXT.title1} {PROPOSAL_TEXT.title2} {PROPOSAL_TEXT.title3}
            </Text>
            <FlexibleList>
                {PROPOSAL_CARDS.map((card, index) => {
                    return (
                        <Card key={index} icon={card.icon.img}>
                            <Text
                                title
                                as={'h3'}
                                marginY={'32px'}
                                alignment={'center'}
                                color={theme.blue1}>
                                {card.text.title}
                            </Text>
                            <Text
                                maxW={90}
                                alignment='center'
                                color={theme.blue2}>
                                {card.text.description}
                            </Text>
                        </Card>
                    );
                })}
            </FlexibleList>
        </Frame>
    );
}

const HomeDisclaimer = (props) => {
    const theme = useTheme();

    return (
        <Frame column>
            <Text
                title
                as={'h2'}
                maxW={'50'}
                marginX={'auto'}
                marginY={'60px'}
                alignment={'center'}
                color={theme.accent1}>
                {DISCLAIMER_TEXT.title1}
            </Text>
            <FlexibleList>
                {DISCLAIMER_CARDS.map((card, index) => {
                    return (
                        <Card key={index} transparent>
                            <Text
                                title
                                as={'h3'}
                                marginY={'20px'}
                                alignment='center'
                                color={theme.white1}>
                                {card.text.title}
                            </Text>
                            <Text
                                maxW={90}
                                alignment='center'
                                color={theme.white2}>
                                {card.text.description}
                            </Text>
                        </Card>
                    );
                })}
            </FlexibleList>
        </Frame>
    );
}

const HomePartners = (props) => {
    const theme = useTheme();
    return (
        <Frame column>
            <Text
                title
                as={'h2'}
                maxW={'50'}
                marginX={'auto'}
                marginY={'60px'}
                alignment={'center'}
                color={theme.white1}>
                {PARTNERS_TEXT.title}
            </Text>
            <FlexibleList>
                {PARTNERS_HOME.map((partner, index) => {
                    return (
                        <Partner 
                            key={index}
                            logo={partner.logo}
                            subtitle={partner.text}
                            social={partner.social}/>
                    );
                })}
            </FlexibleList>
        </Frame>
    );
}

export { HomePresentation, HomeProposal, HomeDisclaimer, HomePartners };