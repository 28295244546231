// todo: multiple themes (light, dark, colored)

const THEMES = {
    main: {
        white1: '#FFFFFF',
        white2: '#DCDCDC',
        black1: '#000000',
        black2: '#282828',
        gray1: '#343434',
        gray2: '#808080',
        gray3: '#CCCCCC',
        blue1: '#16365D',
        blue2: '#1F4A80',
        blue3: '#AAb4C3',
        accent1: '#FAD03A',
        accent2: '#665129',
    },
    light: {

    },
    dark: {

    }
};

export default THEMES;