import styled from "styled-components";

const FlexibleList = styled.div`
    display: flex;
    flex-flow: wrap;
    column-gap: 100px;
    height: 80%;
    margin: 32px 0;
    position: relative;
    justify-content: center;
`;

const AssociateCard = styled.div(({ theme }) => `
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    align-items: center;
    transition: 300ms;

    &:hover {
        transform: scale(1.05);
    }
`);

const AssociatePhoto = styled.div`
    display: flex;
    width: 160px;
    height: 160px;
    border-radius: 220px;
    overflow: hidden;
`;

const AssociateDescription = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 300px;
    row-gap: 20px;
    padding: 12px;
    align-items: center;
`;

export { FlexibleList, AssociateCard, AssociatePhoto, AssociateDescription }