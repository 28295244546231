import { ASSOCIATES_IMG } from "./assets/images";
import { ABOUT_ASSOCIATES_TEXT } from "./strings/about";

const ASSOCIATES = [
    {
        photo: ASSOCIATES_IMG.associate1,
        description: ABOUT_ASSOCIATES_TEXT.associate1,
    },
    {
        photo: ASSOCIATES_IMG.associate2,
        description: ABOUT_ASSOCIATES_TEXT.associate2,
    },
    {
        photo: ASSOCIATES_IMG.associate3,
        description: ABOUT_ASSOCIATES_TEXT.associate3,
    },
];

export { ASSOCIATES }