import { createContext, useEffect, useState } from "react";

// Contexto para manipulação responsiva de UI
export const WindowContext = createContext({
    size: window.innerWidth,
    hideNav: false
});

// Providencia o valor da largura da janela a todo momento
export const WindowProvider = ({ children }) => {
    const [context, setContext] = useState({
        size: window.innerWidth,
        hideNav: window.innerWidth < 1024
    });

    useEffect(() => {
        const handleResize = () => {
            setContext({
                size: window.innerWidth,
                hideNav: window.innerWidth < 1024
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    });

    return (
        <WindowContext.Provider value={context}>
            {children}
        </WindowContext.Provider>
    )
};