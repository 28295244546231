import backgroundPresentation from "../../../assets/images/bg/bg-presentation.png";
import backgroundDisclaimer from "../../../assets/images/bg/bg-disclaimer.png";
import backgroundAbout from "../../../assets/images/bg/bg-about.png";

import logoLegno from "../../../assets/images/partners/legno.png";
import logoIbiubi from "../../../assets/images/partners/ibiubi.png";
import logoDoutjeito from "../../../assets/images/partners/doutjeito.png";

import vitorFernandes from "../../../assets/images/athletes/vitor-fernandes.png";
import vitorOliveira from "../../../assets/images/athletes/vitor-oliveira.png";

import gabriel from "../../../assets/images/associates/gabriel.png"
import gustavo from "../../../assets/images/associates/gustavo.png"
import rodrigo from "../../../assets/images/associates/rodrigo.png"

const BG_IMG = {
    bgPresentation: backgroundPresentation,
    bgDisclaimer: backgroundDisclaimer,
    bgAbout: backgroundAbout,
}

const PARTNERS_IMG = {
    partner1: logoLegno,
    partner2: logoIbiubi,
    partner3: logoDoutjeito,
}

const ATHLETES_IMG = {
    athlete1: vitorFernandes,
    athlete2: vitorOliveira,
}

const ASSOCIATES_IMG = {
    associate1: gabriel,
    associate2: gustavo,
    associate3: rodrigo,
}

export { BG_IMG, PARTNERS_IMG, ATHLETES_IMG, ASSOCIATES_IMG };