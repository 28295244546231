import { ATHLETES } from "./athletes";
import { ASSOCIATES } from "./associates";
import { BG_IMG } from "./assets/images";
import { HomePresentation, HomeProposal, HomeDisclaimer, HomePartners } from "../../components/molecules/HomeContent";
import { AthletesCategories, AthletesPresentation } from "../../components/molecules/AthletesContent";
import { AboutAssociates, AboutPresentation } from "../../components/molecules/AboutContent";

const HOME_SECTIONS = [
    {
        element: <HomePresentation />,
        height: 676,
        background: BG_IMG.bgPresentation,
        color: "#16365D",
    },
    {
        element: <HomeProposal />,
    },
    {
        element: <HomePartners />,
        color: "#16365D",
    },
    {
        element: <HomeDisclaimer />,
        background: BG_IMG.bgDisclaimer,
    },
];

const ATHLETES_SECTIONS = [
    {
        element: <AthletesPresentation />,
        color: "#16365D",
    },
    {
        element: <AthletesCategories athletes={ATHLETES} />,
    },
];

const ABOUT_SECTIONS = [
    {
        element: <AboutPresentation />,
        background: BG_IMG.bgAbout,
        color: "#16365D",
    },
    {
        element: <AboutAssociates associates={ASSOCIATES} />,
    },
]

export { HOME_SECTIONS, ATHLETES_SECTIONS, ABOUT_SECTIONS };