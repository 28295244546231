import { Container } from "./style";

const Frame = ({ column, width, height, mainAlign, altAlign, children }) => {
    return (
        <Container
            $column={column}
            $width={width}
            $height={height}
            $mainAlignment={mainAlign}
            $altAlignment={altAlign}>
            {children}
        </Container>
    );
}

export default Frame;