import { Container } from "./style";
import NavItem from "../../atoms/NavItem";

const NavMenu = ({ drop, visible, items }) => {
    return (
        <Container
            $drop={drop}
            $visible={visible}>
            {items.map((item, index) =>
                <NavItem
                    key={index}
                    path={item.path}
                    name={item.name}
                    drop={drop} />
            )}
        </Container>
    );
}

export default NavMenu;