import styled from "styled-components";

const Header = styled.h1(({
    as,
    $maxW = 100,
    $marginX = 0,
    $marginY = 0,
    $alignment = 'left',
    $color
}) => `
    max-width: ${$maxW}%;
    margin: ${$marginY} ${$marginX};
    text-align: ${$alignment};
    color: ${$color};

    ${as === 'h1' && `
        font-size: 60px;
        font-weight: bold;

        @media screen and (max-width: 768px) {
            font-size: 40px;
            max-width: 80%;
        }
    `};

    ${as === 'h2' && `
        font-size: 48px;
        font-weight: bold;

        @media screen and (max-width: 980px) {
            max-width: 90%;
        }
        
        @media screen and (max-width: 768px) {
            font-size: 30px;
        }
    `};

    ${as === 'h3' && `
        font-size: 36px;
        font-weight: bold;

        @media screen and (max-width: 768px) {
            font-size: 28px;
        }
    `};

    ${as === 'h4' && `
        font-size: 32px;
        font-weight: 400;

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }
    `};

    ${as === 'h5' && `
        font-size: 28px;
        font-weight: 600;

        @media screen and (max-width: 768px) {
            font-size: 20px;
        }
    `};
`);


const Body = styled.span(({ 
    $primary, 
    $maxW = 100, 
    $marginX = 0,
    $marginY = 0,
    $alignment, 
    $color
 }) => `
    max-width: ${$maxW}%;
    margin: ${$marginY} ${$marginX};
    text-align: ${$alignment ? $alignment : 'left'};
    font-size: ${$primary ? '18' : '16'}px;
    font-weight: ${$primary ? 600 : 400};
    color: ${$color};

    @media screen and (max-width: 768px) {
        ${$maxW !== 100 && `max-width: 80%`};
        font-size: ${$primary ? '16px' : '14px'};
    }
`);

export { Header, Body }