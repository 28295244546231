import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, -1);
  }, [pathname]);
}


const ROUTES = [
  {
    name: 'Início',
    path: '/',
    element: <HomePage />,
  },
  // {
  //   name: 'Serviços',
  //   path: '/services',
  //   element: <ServicesPage />
  // },
  // {
  //   name: 'Atletas',
  //   path: '/athletes',
  //   element: <AthletesPage />
  // },
  {
    name: 'Sobre',
    path: '/about',
    element: <AboutPage />
  },
  // {
  //   name: 'Blog',
  //   path: '/blog',
  //   element: <BlogPage />
  // },
];

export default ROUTES;