import { Container } from "./style";
import Section from "../../atoms/Section";

const AboutTemplate = ({ sections }) => {
    return (
        <Container>
            {sections.map((section, index) =>
                <Section
                    key={index}
                    height={section.height}
                    color={section.color}
                    element={section.element}
                    background={section.background}
                />
            )}

        </Container>
    );
}

export default AboutTemplate;