import AppTemplate from './components/templates/AppTemplate';
import ROUTES from './utils/routes';
import THEMES from "./utils/constants/themes";

function App() {
  return (
    <AppTemplate routes={ROUTES} themes={THEMES}/>
  );
}

export default App;
