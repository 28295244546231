import { PARTNERS_IMG } from "./assets/images";
import { PARTNERS_SOCIAL_LINKS, PARTNERS_TEXT } from "./strings/home";

const PARTNERS_HOME = [
    {
        logo: PARTNERS_IMG.partner1,
        text: PARTNERS_TEXT.partner1,
        social: PARTNERS_SOCIAL_LINKS.partner1,
    },
    {
        logo: PARTNERS_IMG.partner2,
        text: PARTNERS_TEXT.partner2,
        social: PARTNERS_SOCIAL_LINKS.partner2,
    },
    {
        logo: PARTNERS_IMG.partner3,
        text: PARTNERS_TEXT.partner3,
        social: PARTNERS_SOCIAL_LINKS.partner3,
    },
];

export { PARTNERS_HOME }