import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 200px;
    max-width: 460px;
    margin-bottom: 12px;
    padding: 40px;
    position: relative;
    align-items: center;
    justify-content: center;
`;

const Social = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    column-gap: 22px;
    margin: 8px 0;
`;

const SocialLink = styled.a``;

export { Container, Social, SocialLink }