import ballIcon from "../../../assets/icons/ball.svg";
import balanceIcon from "../../../assets/icons/balance.svg";
import tribuneIcon from "../../../assets/icons/tribune.svg";

import menuIcon from "../../../assets/icons/menu.svg";
import closeIcon from "../../../assets/icons/close.svg";

import instaIcon from "../../../assets/icons/social/instagram.svg";
import linkeIcon from "../../../assets/icons/social/linkedin.svg";
import facebIcon from "../../../assets/icons/social/facebook.svg";
import whatsIcon from "../../../assets/icons/social/whatsapp.svg";

const ballAttr = <a href="https://thenounproject.com/icon/soccer-ball-1014131/">'Soccer Ball' por andriwidodo</a>;
const balanceAttr = <a href="https://google.com">'Scale'</a>;
const tribuneAttr = <a href="https://thenounproject.com/icon/pulpit-2004546/">'Pulpit' por Georgiana Ionescu.</a>

const ICONS = {
    ball: {
        img: ballIcon,
        attribution: ballAttr
    },
    balance: {
        img: balanceIcon,
        attribution: balanceAttr
    },
    tribune: {
        img: tribuneIcon,
        attribution: tribuneAttr
    },
    menu: {
        img: menuIcon,
    },
    close: {
        img: closeIcon,
    },
    socialInsta: {
        img: instaIcon,
    },
    socialLinke: {
        img: linkeIcon,
    },
    socialFaceb: {
        img:facebIcon,
    },
    socialWhats: {
        img:whatsIcon,
    },
};

export default ICONS;