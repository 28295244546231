import { Container } from "./style";
import Section from "../../atoms/Section";

const HomeTemplate = ({ sections }) => {
    return (
        <Container>
            {sections.map((section, index) =>
                <Section
                    key={index}
                    height={section.height}
                    background={section.background}
                    color={section.color}
                    element={section.element} />
            )}
        </Container>
    );
}

export default HomeTemplate;