// ---- APRESENTAÇÃO
export const PRESENTATION_TEXT = {
    title: "Junte-se a nós e marque o gol da sua carreira!",
    subtitle: "Abra portas para o seu futuro no futebol",
};

// ---- PROPOSTA
export const PROPOSAL_TEXT = {
    title1: "Nós da Smash temos",
    title2: "comprometimento",
    title3: "com você.",
};

export const PROPOSAL_CARDS_TEXT = [
    {
        title: "Auxílio Esportivo",
        description: "Contamos com um Analista de Desempenho Físico especializado, procuramos assegurar sua integridade física.",
    },
    {
        title: "Apoio Jurídico",
        description: "Oferecemos suporte jurídico e gestão empresarial especializados para todos os aspectos de sua carreira esportiva.",
    },
    {
        title: "Trabalho de Imagem",
        description: "Marketing digital, patrocínios e assessoria de imprensa. Vamos ajudar você a se destacar no mercado.",
    },
];

// ---- RESPONSABILIDADE
export const DISCLAIMER_TEXT = {
    title1: "Responsabilidade com a carreira do jovem atleta",
};

export const DISCLAIMER_CARDS_TEXT = [
    {
        title: "Transparência",
        description: "Mantemos uma comunicação aberta e honesta com vocês, pais. Acompanhem de perto os progressos, desafios e decisões relacionadas à carreira de seu filho.",
    },
    {
        title: "Confiança",
        description: "Levamos a educação à sério e fazemos questão de garantir que seu filho continue aprendendo e se desenvolvendo academicamente.",
    },
    {
        title: "Bem-estar",
        description: "Priorizamos a saúde e o desenvolvimento integral dos atletas, levando em conta a condição específica de cada um.",
    },
];

// ---- LINKS
export const LINKS = {
    instagram: "https://www.instagram.com/smashsportsmgmt/",
}

// ---- PARCEIROS
export const PARTNERS_TEXT = {
    title: "Parceiros",
    partner1: "Maior fábrica de macas do Brasil",
    partner2: "Consultoria de Gestão & Pessoas",
    partner3: "Gestão de pequenos negócios",
}

export const PARTNERS_SOCIAL_LINKS = {
    partner1: [
        {
            id: 0,
            link: "https://www.instagram.com/legno_macas"
        },
        {
            id: 1,
            link: "https://api.whatsapp.com/send?phone=5531987420135"
        },
    ],
    partner2: [
        {
            id: 0,
            link: "https://www.instagram.com/ibiubigestao"
        },
    ],
    partner3: [
        {
            id: 0,
            link: "https://www.instagram.com/doutjeito/"
        },
        {
            id: 1,
            link: "https://api.whatsapp.com/send?phone=5531996950174"
        },
    ]
 }