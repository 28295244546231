import { createContext, useState } from "react";
import { WindowProvider } from "./window";
import { MenuProvider } from "./menu";

export const AppContext = createContext({
    isLoading: false,
    setIsLoading: () => { },
});

export const AppProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    const context = {
        isLoading,
        setIsLoading,
    };

    return (
        <AppContext.Provider value={context}>
            <WindowProvider>
                <MenuProvider>
                    {children}
                </MenuProvider>
            </WindowProvider>
        </AppContext.Provider>
    )
};