import { Body, Header } from "./style"

const Text = ({ title, as, primary, maxW, marginX, marginY, alignment, color, children }) => {
    return title ?
        <Header
            as={as}
            $maxW={maxW}
            $marginX={marginX}
            $marginY={marginY}
            $alignment={alignment}
            $color={color}>
            {children}</Header>
        :
        <Body
            $primary={primary}
            $maxW={maxW}
            $marginX={marginX}
            $marginY={marginY}
            $alignment={alignment}
            $color={color}>
            {children}</Body>
}

export default Text;