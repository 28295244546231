import { useContext } from "react";

import { Container } from "./style";
import Frame from "../../atoms/Frame";
import { BottomContact, BottomLogo } from "../../molecules/FooterContent";
import { WindowContext } from "../../../context/window";

const Footer = () => {
    const windowContext = useContext(WindowContext);

    const column = windowContext.hideNav;

    return (
        <Container>
            <Frame
                column={column}
                mainAlign={'space-between'}
                altAlign={'center'}>
                <BottomLogo column={column} />
                <BottomContact column={column} />
            </Frame>
        </Container>
    );
}

export default Footer;