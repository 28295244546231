import { useContext } from "react";


import { Container } from "./style"
import Logo from "../../atoms/Logo";
import Image from "../../atoms/Image";
import Frame from "../../atoms/Frame";
import Button from "../../atoms/Button";
import NavMenu from "../../molecules/NavMenu";
import ICONS from "../../../utils/constants/assets/icons";
import { MenuContext } from "../../../context/menu";
import { WindowContext } from "../../../context/window";

const Header = ({ navItems }) => {
    const menuContext = useContext(MenuContext);
    const windowContext = useContext(WindowContext);

    return (
        <Container>
            <Frame altAlign={'center'}>
                <Logo />
                {windowContext.hideNav ?
                    <>
                        <Button
                            transparent
                            header
                            click={() => menuContext.setIsOpen(!menuContext.isOpen)}>
                            <Image
                                size={'24px'}
                                src={!menuContext.isOpen ? ICONS.menu.img : ICONS.close.img} />
                        </Button>
                        <NavMenu
                            drop
                            visible={menuContext.isOpen}
                            items={navItems} />
                    </>
                    :
                    <>
                        <Button
                            transparent
                            header
                            click={() => window.open("mailto:smashsportsmgmt@gmail.com").focus()}>
                            Entrar em contato
                        </Button>
                        <NavMenu items={navItems} />
                    </>
                }
            </Frame>
        </Container>
    );
}

export default Header;