import { useContext } from "react";

import { Item } from "./style";
import Text from "../Text";
import { MenuContext } from "../../../context/menu";

const NavItem = ({ path, name, drop }) => {
    const menuContext = useContext(MenuContext);

    const handleMenu = () => {
        if (drop) {
            menuContext.setIsOpen(!menuContext.isOpen);
        }
    }

    return (
        <Item
            to={path}
            $drop={drop}
            onClick={handleMenu}>
            <Text alignment={drop && 'end'}>
                {name}
            </Text>
        </Item>
    );
}

export default NavItem;