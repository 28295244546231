import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    max-width: 240px;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media screen and (max-width: 768px){
        width: 70%;
    }
`;

export { Container };