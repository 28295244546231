import styled from "styled-components";

const Box = styled.div(({ $center, $column, $margin, $gap }) => `
    display: flex;
    ${$column && `flex-direction: column`};
    column-gap: ${$gap ? 80 : 8}px;
    ${$margin && `margin: 40px 0`};
    ${$center && `align-items: center`};
`);

export { Box }