import { createContext, useState } from "react";

export const MenuContext = createContext({
    isOpen: false,
    setIsOpen: () => { },
});

export const MenuProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const context = {
        isOpen,
        setIsOpen,
    };

    return (
        <MenuContext.Provider value={context}>
            {children}
        </MenuContext.Provider>
    )
};
