import { MainButton } from "./style";
import Text from "../Text";

const Button = ({ transparent, header, click, children }) => {
    return (
        <MainButton
            $transparent={transparent}
            $header={header}
            onClick={click}>
            <Text>{children}</Text>
        </MainButton>
    );
}

export default Button;