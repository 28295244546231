import { Container, IconContainer } from "./style";
import Image from "../Image";

const Card = ({ transparent, width, height, icon, alignment, children }) => {
    return (
        <Container
            $transparent={transparent}
            $width={width}
            $height={height}
            $alignment={alignment}>
            {
                icon &&
                <IconContainer>
                    <Image size='120px' src={icon} />
                </IconContainer>
            }
            {children}
        </Container >
    );
}

export default Card;