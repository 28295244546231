import { useContext } from "react";
import { Panel } from "./style"
import { MenuContext } from "../../../context/menu";

const Overlay = () => {
    const menuContext = useContext(MenuContext);

    return (
        <Panel
            $active={menuContext.isOpen}
            onClick={() => { menuContext.setIsOpen(!menuContext.isOpen) }} />
    );
}

export default Overlay;