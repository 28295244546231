import styled from "styled-components";

const FlexibleList = styled.div`
    display: flex;
    flex-flow: wrap;
    column-gap: 100px;
    height: 80%;
    margin: 32px 0;
    position: relative;
    justify-content: center;
`;

const AthleteCard = styled.div(({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 520px;
    margin-bottom: 100px;
    border-radius: 24px;
    background-color: ${theme.blue1};
    overflow: hidden;
    transition: 300ms;
    clip-path: polygon( 0 0, 100% 0, 100% 90%, 50% 100%, 0 90%);

    &:hover {
        transform: scale(1.05);
    }
`);

const AthletePhoto = styled.div(({ theme }) => `
    display: flex;
    flex: 1;
    border-bottom: 2px solid ${theme.accent1};
    overflow: hidden;
`);

const AthleteDescription = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    height: 200px;
    padding: 12px;
    align-items: center;
    box-sizing: border-box;
`;

const Line = styled.div(({ 
    width = 100, 
    theme 
}) => `
    width: ${width}%;
    height: 2px;
    margin-bottom: 4px;
    border-radius: 4px;
    background-color: ${theme.accent1};
`);

export { FlexibleList, AthleteCard, AthletePhoto, AthleteDescription, Line }