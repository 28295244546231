import styled from "styled-components";

const Panel = styled.div(({ $active }) => `
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: background-color ease-in 400ms;
    z-index: -1;

    ${$active && `
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 2;
    `}
`);

export { Panel }