import { Container } from "./style";
import logo from "../../../assets/images/logo.png";

const Logo = () => {
    return (
        <Container>
            <img src={logo} alt='Logo de Smash Sports Management'/>
        </Container>
    );
}

export default Logo;