import styled from 'styled-components';

const Container = styled.div(({$transparent, $width, $height, $alignment, theme}) => `
    display: flex;
    flex-direction: column;
    min-width: 200px;
    ${$width && `width: ${$width}`};
    max-width: 460px;
    ${$height && `height: ${$height}`};
    margin-bottom: 100px;
    padding: 40px;
    position: relative;
    align-items: center;
    justify-content: ${$alignment ? $alignment : 'center'};
    border: 1px solid white;
    border-radius: 28px;
    background-color: ${theme.white1};
    transition: 200ms;
    
    &:hover {
        transform: scale(1.05);
    }

    ${$transparent && `
        background-color: transparent;
        backdrop-filter: blur(40px);
    `}
    
`);

const IconContainer = styled.div`
    display: flex;
    position: absolute;
    top: -60px;
`;

export { Container, IconContainer };