import styled from "styled-components";

const Background = styled.div(({ paddingX = 0, paddingY = 0, image }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-image: url(${image});
`);

const FlexibleList = styled.div`
    display: flex;
    flex-flow: wrap;
    column-gap: 100px;
    height: 80%;
    margin: 60px 0;
    position: relative;
    justify-content: center;
`;

export { Background, FlexibleList }