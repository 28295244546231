export const LINKS = {
    linkedin: "https://www.linkedin.com/",
    instagram: "https://www.instagram.com/smashsportsmgmt/",
};

export const CONTACTS = {
    email: "smashsportsmgmt@gmail.com",
    phone1: "(31) 9 9996-1511",
    phone2: "(31) 9 9899-0892",
};

export const CC = {
    cc: "© 2024 Smash Sports Management",
};
