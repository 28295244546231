import { styled } from 'styled-components';

const Container = styled.header(({ theme }) => `
    display: flex;
    flex-direction: row;
    height: 80px;
    padding: 0 8vw;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: ${theme.blue1};
    z-index: 2;
`);

export { Container };