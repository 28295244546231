import styled from "styled-components";

const Container = styled.footer(({ theme }) => `
    display: flex;
    width: 100%;
    min-height: 250px;
    padding: 0 8vw;
    align-items: center;
    background-color: ${theme.blue1};
`);

export { Container };