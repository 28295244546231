import { useTheme } from "styled-components";

import { Box } from "./style";
import Logo from "../../atoms/Logo";
import Text from "../../atoms/Text";
import Image from "../../atoms/Image";
import Button from "../../atoms/Button";
import ICONS from "../../../utils/constants/assets/icons";
import { CC, CONTACTS, LINKS } from "../../../utils/constants/strings/info";

const BottomLogo = ({ column }) => {
    const theme = useTheme();

    return (
        <Box $center $column $margin={column}>
            <Logo />
            <Text
                color={theme.white2}
                marginY={'20px'}
                alignment={'center'}>{CC.cc}</Text>
        </Box>
    );
}

const BottomContact = ({ column }) => {
    const theme = useTheme();

    const openUrl = (url) => {
        window.open(url, "_blank", "noreferrer");
    }

    return (
        <Box
            $center={column}
            $column={column}
            $gap={!column}>
            <Box $column>
                <Text
                    primary
                    marginY={'8px'}
                    alignment={column && 'center'}
                    color={theme.white2}>
                    Contato
                </Text>
                <Text alignment={column && 'center'} color={theme.white2}>{CONTACTS.email}</Text>
                <Text marginY={'4px'} alignment={column && 'center'} color={theme.white2}>{CONTACTS.phone1}</Text>
                <Text alignment={column && 'center'} color={theme.white2}>{CONTACTS.phone2}</Text>
            </Box>
            <Box $column $center $margin={column} >
                <Text
                    primary
                    marginY={'8px'}
                    alignment={column && 'center'}
                    color={theme.white2}>
                    Redes Sociais
                </Text>
                <Box>
                    <Button transparent click={() => openUrl(LINKS.instagram)}>
                        <Image size={'24px'} src={ICONS.socialInsta.img} />
                    </Button>
                    {/* <Button transparent>
                        <Image size={'24px'} src={ICONS.socialLinke.img} />
                    </Button> */}
                </Box>
            </Box>
        </Box>
    );
}

export { BottomLogo, BottomContact };