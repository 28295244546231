import styled from 'styled-components';

const Container = styled.div(({
    $column,
    $width = '100%',
    $height = '100%',
    $mainAlignment,
    $altAlignment
}) => `
    -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: content-box;    /* Firefox, other Gecko */
    box-sizing: content-box;         /* Opera/IE 8+ */

    display: flex;
    ${$column && `flex-direction: column`};
    width: ${$width};
    max-width: 1616px;
    height: ${$height};
    position: relative;
    ${$altAlignment && `align-items: ${$altAlignment}`};
    ${$mainAlignment && `justify-content: ${$mainAlignment}`};

`);


export { Container };