import styled from "styled-components";
import { Link } from "react-router-dom";


const Item = styled(Link)(({ $drop, theme }) => `
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    text-decoration: none;
    span {
        ${$drop && `padding-right: 8vw;`}
        width: 100%;
        color: ${theme.white2};
        &:after {
            height: 2px;
            width: auto;
            position: absolute;
            left: 0; 
            right: 0;
            bottom: 20px;
            opacity: 1;
            background-color: ${theme.accent1};
            transform: scaleX(0);
            transition-duration: 400ms;
            content: '';
        }
    }
    &:hover {
        span {
            color:  ${theme.white1};
            cursor: pointer;
            ${$drop ? `background-color: rgba(0, 0, 0, 0.2);` : `&::after { transform: scaleX(1); }`}
        }
    }
`);

export { Item };