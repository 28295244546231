import { useTheme } from "styled-components";

import { AssociateCard, AssociateDescription, AssociatePhoto, FlexibleList } from "./style";
import Frame from "../../atoms/Frame";
import Text from "../../atoms/Text";
import Image from "../../atoms/Image";
import { ABOUT_TEXT } from "../../../utils/constants/strings/about";

const AboutPresentation = () => {
    const theme = useTheme();

    return (
        <Frame column>
            <Text
                title
                as={'h2'}
                marginY={'64px'}
                color={theme.accent1}>
                {ABOUT_TEXT.title}
                <i>
                <Text
                    title
                    as={'h4'}
                    marginY={'20px'}
                    color={theme.white1}>
                    {ABOUT_TEXT.subtitle}
                </Text>
                </i>
            </Text>
            <Text
                primary
                maxW={40}
                marginY={'20px'}
                color={theme.white1}
            >
                {ABOUT_TEXT.description1}
            </Text>
            <Text
                primary
                maxW={50}
                marginY={'40px'}
                color={theme.white1}
            >
                {ABOUT_TEXT.description2}
            </Text>
        </Frame>
    );
}

const AboutAssociates = ({ associates }) => {
    const theme = useTheme();

    return (
        <Frame column altAlign={'center'}>
            <Text
                title
                as={'h3'}
                marginX={'auto'}
                marginY={'60px'}
                alignment={'center'}
                color={theme.blue1}>
                {ABOUT_TEXT.title2}
            </Text>
            <FlexibleList>
                {associates.map((associate, index) =>
                    <AssociateCard key={index}>
                        <AssociatePhoto>
                            <Image src={associate.photo} />
                        </AssociatePhoto>
                        <AssociateDescription>
                            <Text
                                title
                                as={'h5'}
                                marginY={'8px'}
                                color={theme.blue1}
                            >
                                {associate.description.name}
                            </Text>
                            <Text color={theme.blue2}>
                                {associate.description.resume}
                            </Text>
                        </AssociateDescription>
                    </AssociateCard>
                )}
            </FlexibleList>
        </Frame>
    );
}

export { AboutPresentation, AboutAssociates };