import styled from "styled-components";

const MainButton = styled.button(({ $transparent, $header, theme }) => `
    height: 48px;
    padding: 10px 12px;
    border: 2px solid ${theme.accent1};
    border-radius: 8px;
    
    color: ${$transparent ? theme.accent1 : theme.gray1};
    background-color: ${$transparent ? 'transparent' : theme.white2};
    transition: 700ms;
    cursor: pointer;

    &:hover {
        border: 2px solid ${$transparent ? theme.accent1 : theme.gray2};
        color: ${theme.accent1};
        background-color: rgba(250, 208, 58, 0.2);
    }

    ${$header && `
        position: absolute;
        right: 0;
        z-index: 4;
    `}
`);

export { MainButton }