import { ThemeProvider } from 'styled-components';

import { AppProvider } from '../../../context/root';
import RouterTemplate from '../RouterTemplate';

const AppTemplate = ({ routes, themes }) => {
    // const [theme, setTheme] = useState(themes.main);

    return (
        <ThemeProvider theme={themes.main}>
            <AppProvider>
                <RouterTemplate routes={routes} />
            </AppProvider>
        </ThemeProvider>
    );
}

export default AppTemplate;
