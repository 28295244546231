// ---- TÍTULO
export const ABOUT_TEXT = {
    title: "Smash Sports Management",
    subtitle: "We play together",
    description1: "A Smash foi fundada com o intuito de alavancar a carreira de atletas que aspiram atuar profissionalmente e progredir no mundo do futebol.",
    description2: "Somos um time de profissionais com especialidades únicas, voltadas para contribuir com o seu desenvolvimento.",
    title2: "A nossa equipe:",
};

export const ABOUT_ASSOCIATES_TEXT = {
    associate1: {
        name: "Gabriel Silva",
        resume: "Bacharel em direito, curso de direito desportivo no Instituto de Direito Contemporâneo, seminário de direito desportivo na faculdade Dom Helder Câmara, curso de agente FIFA na WS Consultoria. Inglês fluente e espanhol intermediário.",
    },
    associate2: {
        name: "Gustavo França",
        resume: "Bacharel, Mestre e Doutor em Direito, curso de Formação de Oficiais da PMMG, Ex-Professor de Direito Internacional Público e Privado, Ex-atleta de Judô da PMMG, Faixa preta de Taekwondo, Faixa roxa de Jiu Jitsu, Co-autor de livro na área internacional, autor de artigos jurídicos e mediador formado pela PUC-MG. Fluente em inglês, alemão e grego, francês intermediário.",
    },
    associate3: {
        name: "Rodrigo Amorim",
        resume: "Bacharel em Educação Física pela UFMG com experiência nas áreas de futebol, futsal, natação e atividade física adaptada. Cursos em Análise de Desempenho no Futebol e Futebol de Base, incluindo certificações da UFMG, UNIGRA - Centro de Formação em Ciências do Esporte e Futebol Interativo. Estudos científicos na área de Comportamento Motor, Fisiologia do Exercício e Futebol. Trabalhos realizados incluem Design Gráfico, Web Design e Edição de Conteúdo.",
    },
};